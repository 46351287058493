@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-cyrillic-ext-300-normal.12d37040.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-cyrillic-300-normal.48e4b37e.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-greek-ext-300-normal.04e05839.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-greek-300-normal.7c6b44c5.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-vietnamese-300-normal.af9afdc1.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-latin-ext-300-normal.a29236e0.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/static/media/roboto-latin-300-normal.0109a2ac.woff2) format('woff2'), url(/static/media/roboto-all-300-normal.8fc5f5f2.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-cyrillic-ext-400-normal.493afe7a.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-cyrillic-400-normal.5e493812.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-greek-ext-400-normal.bb723e84.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-greek-400-normal.1cbfc636.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-vietnamese-400-normal.c0bec65d.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-latin-ext-400-normal.c3dcdbd5.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/static/media/roboto-latin-400-normal.4673b453.woff2) format('woff2'), url(/static/media/roboto-all-400-normal.376ea5d9.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-cyrillic-ext-500-normal.3e748c93.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-cyrillic-500-normal.d1615fb9.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-greek-ext-500-normal.9c0d384f.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-greek-500-normal.007ca18d.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-vietnamese-500-normal.b8e494da.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-latin-ext-500-normal.feaff916.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/static/media/roboto-latin-500-normal.86988841.woff2) format('woff2'), url(/static/media/roboto-all-500-normal.52cb737b.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-cyrillic-ext-700-normal.b816cda3.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-cyrillic-700-normal.0334efc0.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-greek-ext-700-normal.9674bc0a.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-greek-700-normal.3292e831.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-vietnamese-700-normal.3096f18a.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-latin-ext-700-normal.bcf37d66.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/static/media/roboto-latin-700-normal.0682ca7f.woff2) format('woff2'), url(/static/media/roboto-all-700-normal.ef6d1d09.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.wallet-adapter-button {
    background-color: #404144;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}

.wallet-adapter-button-trigger {
    background-color: #4e44ce;
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.wallet-adapter-button-end-icon {
    margin-left: 8px;
}

.wallet-adapter-button-start-icon {
    margin-right: 8px;
}

.wallet-adapter-collapse {
    width: 100%;
}

.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
    background: #404144;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: white;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-modal-collapse-button svg {
    align-self: center;
    fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    transform: rotate(180deg);
    transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}

.wallet-adapter-modal-button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 6px;
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: white;
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}

.wallet-adapter-modal-logo {
    max-width: 100%;
}

.wallet-adapter-modal-logo-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: #141515;
    margin-bottom: 10px;
    padding: 12px;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wallet-adapter-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
}

.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #2c2d30;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 40px 20px 20px;
    flex: 1 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-modal-wrapper-no-logo {
    padding-top: 30px;
}

.wallet-adapter-modal-title {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 27px;
    margin-top: 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 26px;
    }
}

.wallet-adapter-modal-list {
    margin: 0 0 12px;
    padding: 0;
    width: 100%;
    list-style: none;
}

.wallet-adapter-modal-list li:not(:first-of-type) {
    margin-top: 12px;
}

